html, body {
  height: 100%;
}



.react-datepicker__input-container input {
    display: block;
    padding: 10px 10px;
    margin: 0px;
    background-color: #fff;
    outline: 0;
    font-size: 13px;
    font-family: Inter;
    font-weight: 500;
    border-radius: 6px;
    cursor:pointer;
    font-family: sans-serif;
}

.time-input {
  display: block;
    padding: 10px 10px;
    margin: 0px;
    background-color: #fff;
    outline: 0;
    font-size: 13px;
    font-family: Inter;
    font-weight: 500;
    border-radius: 6px;
    cursor:pointer;
    text-align: left;
    min-height: 39.5px;
    font-family: sans-serif;
}


/* .react-datepicker-wrapper,
.react-datepicker__input-container, */
